import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import Classnames from 'classnames';
import componentStyles from './Tile.module.scss';
import { FaArrowRight } from 'react-icons/fa';

const Tile = ({ icon, header, link, external, ...props }) => {
	return (
		<>
			{link && !link.external && (
				<Link className={Classnames(componentStyles.tile, componentStyles.linkTile)} to={link.path}>
					{icon}
					{header && <h3>{header}</h3>}
					{props.children}

					<div className={componentStyles.link} to={link.path}>
						<span>{link.text}</span>
						<FaArrowRight className={componentStyles.arrow} />
					</div>
				</Link>
			)}

			{link && link.external && (
				<a className={Classnames(componentStyles.tile, componentStyles.linkTile)} href={link.path}>
					{icon}
					{header && <h3>{header}</h3>}
					{props.children}

					<div className={componentStyles.link} to={link.path}>
						<span>{link.text}</span>
						<FaArrowRight className={componentStyles.arrow} />
					</div>
				</a>
			)}

			{!link && (
				<div className={componentStyles.tile}>
					{icon}
					{header && <h3>{header}</h3>}
					{props.children}
				</div>
			)}
		</>
	);
};

export default Tile;
