import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './Alert.module.scss';
import { FaExclamationTriangle, FaFlag, FaCheckCircle } from 'react-icons/fa';

const Alert = ({ type, header, align, ...props }) => {
	// types: neutral (default), warning, error, success
	let icon;
	if (type === 'success') icon = <FaCheckCircle />;
	else if (type === 'warning' || type === 'error') icon = <FaExclamationTriangle />;
	else icon = <FaFlag />;

	let classes;
	if (header && align !== 'center') classes = ClassNames(componentStyles.alert, componentStyles[type]);
	else if (header && align === 'center')
		classes = ClassNames(componentStyles.alert, componentStyles.center, componentStyles[type]);
	else if (!header && align === 'center')
		classes = ClassNames(
			componentStyles.alert,
			componentStyles.center,
			componentStyles.flex,
			componentStyles[type]
		);
	else classes = ClassNames(componentStyles.alert, componentStyles.flex, componentStyles[type]);

	return (
		<div className={classes}>
			<div className={header ? componentStyles.header : ''}>
				<span className={componentStyles.icon}>{icon}</span>
				{header && <h2>{header}</h2>}
			</div>

			<div>{props.children}</div>
		</div>
	);
};

export default Alert;
